import apiRequest from "./api";

export const fetchUserCount = async () => {
  try {
    const response = await apiRequest('/api/user/user-count');

    if (!response.ok) throw new Error("Failed to fetch user count");

    const data = await response.json();

    return data.userCount;
  } catch (error) {
    console.error("Failed to fetch user count:", error);
    return null;
  }
};
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useUser } from '../contexts/UserContext';
import apiRequest from '../utils/api';
import Request from '../types/Request';
import Modal from 'react-modal';
import RequestModal from './RequestModal';
import FetchError from '../types/Error';
import Loader from './Loader';
import { RequestListProps } from '../types/Props';
import { calculateDistance, useUserLocation } from '../utils/locationUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faRotateRight, faLocationDot, faHandshakeAngle, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { formatDistanceStrict } from 'date-fns';
import { enUS, fi, sv } from 'date-fns/locale';

const RequestList: React.FC<RequestListProps> = ({ showAll, refreshTrigger }) => {
  const { translations, language } = useLanguage();
  const { user } = useUser();
  const [requests, setRequests] = useState<Request[]>([]);
  const [currentRequestId, setCurrentRequestId] = useState<number | null>(null);
  const [isUserRequest, setIsUserRequest] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [isRequestModalOpen, setRequestModalOpen] = useState<boolean>(false);
  const {userLatitude, userLongitude} = useUserLocation();
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const statusColor = {
    'new': 'status-red',
    'in progress': 'status-yellow',
    'completed': 'status-green'
  }

  const statusOutline = {
    'new': 'status-outline-red',
    'in progress': 'status-outline-yellow',
    'completed': 'status-outline-green'
  }

  const fetchRequests = useCallback(async () => {
    if (!user) return;

    try {
      const response = await apiRequest('/api/requests');
      if (!response.ok) {
        throw new Error(translations.errors.failedtofetchrequests);
      }
      const data = await response.json();
      setRequests(data);
    } catch (error) {
      const fetchError = error as FetchError;
      setError(fetchError.message);
    } finally {
      setLoading(false);
    }
  },  [user, translations.errors.failedtofetchrequests]);

  useEffect(() => {
    fetchRequests();
  }, [refreshTrigger, fetchRequests]);

  useEffect(() => {
    if (user) {
      fetchRequests();
    }
  }, [user, updateTrigger, fetchRequests]);

  useEffect(() => {
    const eventSource = new EventSource('/api/requests/updates');
    eventSource.onopen = () => {
    };
    eventSource.onmessage = (event) => {
      if (event.data === 'update') {
        setUpdateTrigger(prev => !prev);
      }
    };

    eventSource.onerror = (error) => {
      console.error('SSE error:', error);
    };

    return () => {
      eventSource.close();
    };
  }, []);

  if (loading || !user) return <Loader />;

  if (error) return <div>Error: {error}</div>;

  const compareRequests = (a: Request, b: Request) => {
    // User's own requests that are not completed at the top
    if (a.isOwnRequest && a.status !== 'completed' && (!b.isOwnRequest || b.status === 'completed')) {
      return -1;
    }
    if (b.isOwnRequest && b.status !== 'completed' && (!a.isOwnRequest || a.status === 'completed')) {
      return 1;
    }

    // Requests that the user is responding sorted next
    if (a.responder_id === user.user_id && b.responder_id !== user.user_id) {
      return -1;
    }
    if (b.responder_id === user.user_id && a.responder_id !== user.user_id) {
      return 1;
    }
  
    // Requests with status 'completed' at the bottom
    if (a.status === 'completed' && b.status !== 'completed') {
      return 1;
    }
    if (a.status !== 'completed' && b.status === 'completed') {
      return -1;
    }

    // Most recently updated requests first
    if (a.updated_at > b.updated_at) {
      return -1;
    }
    if (a.updated_at < b.updated_at) {
        return 1;
    }
  
    return 0;
  };

  const filterOldRequests = requests.filter((request) => {
    // Filter out requests that are older than 24 hours if they are completed
    if (request.status === 'completed') {
      const hoursSinceCompletion = (Date.now() - new Date(request.updated_at).getTime()) / (1000 * 60 * 60);
      if (hoursSinceCompletion > 24) {
        return false;
      }
    }

    // Display test requests only to testers
    if (request.is_test && !user.isAdmin) {
      return false;
    }

    return true;
  });

  const sortedRequests = filterOldRequests.sort(compareRequests);

  const requestsWithinRadius = filterOldRequests.filter((request) => {
    // Always show user's own requests and requests they are responding to
    if (request.isOwnRequest) return true;
    if (request.responder_id === user.user_id) return true;

    // Always show requests with no location shared or when user's location not available
    if (!request.latitude || !request.longitude) return true;
    if (!userLatitude && !userLongitude) return false;
    
    const dist = calculateDistance({
      latitude: request.latitude,
      longitude: request.longitude,
      userLatitude,
      userLongitude
    });

    return dist !== null && dist <= user?.radius;
  });

  const handleUpdate = () => {
    setUpdateTrigger(prev => !prev);
  };

  return (
    <div>
      {(showAll ? filterOldRequests : requestsWithinRadius).length > 0 ? (
        (showAll ? sortedRequests : requestsWithinRadius).map(request => (
          <div key={request.request_id} 
            onClick={() => {
              setCurrentRequestId(request.request_id);
              setIsUserRequest(request.isOwnRequest);
              setRequestModalOpen(true);
            }}
            className={`card request-container 
              ${request.isOwnRequest ? 'own-request' : ''} 
              ${request.responder_id === user.user_id ? 'responding-request' : ''}
              ${statusOutline[request.status] || ''}`}
          >
            <div className="request-item">
              <FontAwesomeIcon icon={faUser} className='listIcon'/>
              { request.isOwnRequest ? (
                translations.ownrequest
              ) : (
                request.user_name
              )}
            </div>
            <div className="request-item category">
              {translations[request.category]}
            </div>
            <div className="request-item">
              {!request.isOwnRequest ? (
                <>
                <FontAwesomeIcon icon={faLocationDot} className='listIcon'/>
                { request.latitude && request.longitude ? (
                  userLatitude && userLongitude ? (
                    calculateDistance({
                      latitude: request.latitude,
                      longitude: request.longitude,
                      userLatitude,
                      userLongitude
                    })?.toFixed(2) + ' ' + translations.kmaway
                  ) : (
                    translations.couldnotcalculatedistance
                  ) 
                ) : (
                  translations.locationnotshared
                )}
                </>
              ) : (
                <>
                <FontAwesomeIcon icon={faLocationDot} className='listIcon'/> 
                { request.latitude && request.longitude ? (
                  translations.locationshared
                ) : (
                  translations.locationnotshared
                )}
                </>
              )}
            </div>
            <div className="request-item">
              <span className={`status-circle ${statusColor[request.status] || ''}`}></span>
              {translations[request.status]}
            </div>
            <div className="request-item updated">
              <FontAwesomeIcon icon={faRotateRight} className='listIcon'/>
              <span className='text-spacer'>{translations.lastupdated}</span>
              {formatDistanceStrict(new Date(request.updated_at), new Date(), { 
                addSuffix: true, 
                locale: language === 'finnish' ? fi : language === 'swedish' ? sv : enUS 
              })}
            </div>
            {request.responder_id === user.user_id && (
              <div className="request-item">
                <FontAwesomeIcon icon={faHandshakeAngle} className='listIcon'/>
                {translations.responding}
              </div>
            )}
          </div>
        ))
      ) : (
        <div className='no-requests'>
          <FontAwesomeIcon icon={faThumbsUp} className='empty-icon'/><br />
          <h2>{translations.allgood}</h2>
          {showAll ? translations.noactiverequests : translations.norequestsforyou}
        </div>
      )}
      <Modal 
        isOpen={isRequestModalOpen} 
        onRequestClose={() => setRequestModalOpen(false)} 
        ariaHideApp={false} 
        className="modal"  
      >
        <RequestModal 
          onClose={() => setRequestModalOpen(false)} 
          isUserRequest={isUserRequest} 
          request_id={currentRequestId ?? 0}
          onUpdate={handleUpdate}
        />
      </Modal>
    </div>
  );
};

export default RequestList;
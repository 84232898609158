import React, { useEffect, useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { APIProvider, Map, AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { GoogleMapProps } from '../types/Props';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const mapsId = process.env.REACT_APP_GOOGLE_MAPS_ID;

const MapWithMarkers: React.FC<GoogleMapProps> = ({ latitude, longitude, userLatitude, userLongitude, isApproximate }) => {
  const { translations } = useLanguage();
  const [zoomLevel, setZoomLevel] = useState<number>(10);
  const map = useMap();

  const handleZoomChange = (map: google.maps.Map | null) => {
    if (map) {
      const newZoom = map.getZoom() ?? 10;
      setZoomLevel(newZoom);
    }
  };

  useEffect(() => {
    if (map && userLatitude != null && userLongitude != null) {
      const bounds = new window.google.maps.LatLngBounds();
      const requestLocation = new window.google.maps.LatLng(latitude, longitude);
      const userLocation = new window.google.maps.LatLng(userLatitude, userLongitude);

      bounds.extend(requestLocation);
      bounds.extend(userLocation);

      map.fitBounds(bounds);
      
    }
  }, [map, latitude, longitude, userLatitude, userLongitude]);

  // Draw a circle around the request location if the location data is approximate
  useEffect(() => {
    if (map && isApproximate) {
      const roundedLatitude = parseFloat(latitude.toFixed(2));
      const roundedLongitude = parseFloat(longitude.toFixed(2));

      const circle = new window.google.maps.Circle({
        center: { lat: roundedLatitude, lng: roundedLongitude },
        radius: 2000,
        fillColor: "#ff5454",
        fillOpacity: 0.3,
        strokeColor: "#ff5454",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        map: map,
      });

      return () => {
        circle.setMap(null);
      };
    }
  }, [map, latitude, longitude, isApproximate, translations]);

  if (!mapsId) {
    return <div>{translations.errors.maperror}</div>;
  }

  // Draw marker if the location is not approximate or if zoom level is low
  const showMarker = (!isApproximate) || (isApproximate && zoomLevel < 10);

  return (
    <Map
      defaultZoom={10}
      defaultCenter={{ lat: latitude, lng: longitude }}
      mapId={mapsId}
      className='map'
      streetViewControl={false}
      onZoomChanged={() => handleZoomChange(map)}
    >
      {showMarker && (
        <AdvancedMarker position={{ lat: latitude, lng: longitude }}>
          <div className='marker-wrapper'>
            <img src="/request_marker.png" width={42} height={42} alt='Request Marker' />
            <span className='marker-text'>{translations.request}</span>
          </div>
        </AdvancedMarker>
      )}
      {userLatitude != null && userLongitude != null && (
        <AdvancedMarker position={{ lat: userLatitude, lng: userLongitude }}>
          <div className='own-location-marker'>
            <div className='own-location-marker-outer' />
            <div className='own-location-marker-inner' />
          </div>
        </AdvancedMarker>
      )}
    </Map>
  );
}

const GoogleMap: React.FC<GoogleMapProps> = ({ latitude, longitude, userLatitude, userLongitude, isApproximate }) => {
  const { translations } = useLanguage();

  if (!apiKey) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <div>
      <APIProvider apiKey={apiKey} libraries={['geometry']}>
        <div style={{ width: '100%', height: '300px' }}>
          <MapWithMarkers
            latitude={latitude}
            longitude={longitude}
            userLatitude={userLatitude}
            userLongitude={userLongitude}
            isApproximate={isApproximate}
          />
        </div>
      </APIProvider>
    </div>
  );
}

const OwnRequestMap: React.FC<GoogleMapProps> = ({ latitude, longitude, isApproximate }) => {
  const { translations } = useLanguage();

  if (!apiKey || !mapsId) {
    return <div>{translations.errors.maperror}</div>;
  }

  return (
    <div>
      <APIProvider apiKey={apiKey} libraries={['geometry']}>
        <div style={{ width: '100%', height: '300px' }}>
          <OwnRequestMapContent latitude={latitude} longitude={longitude} isApproximate={isApproximate} />
        </div>
      </APIProvider>
    </div>
  );
}

const OwnRequestMapContent: React.FC<GoogleMapProps> = ({ latitude, longitude, isApproximate }) => {
  const map = useMap();

  useEffect(() => {
    if (map && isApproximate) {
      const roundedLatitude = parseFloat(latitude.toFixed(2));
      const roundedLongitude = parseFloat(longitude.toFixed(2));

      const circle = new window.google.maps.Circle({
        center: { lat: roundedLatitude, lng: roundedLongitude },
        radius: 2000,
        fillColor: "#ff5454",
        fillOpacity: 0.3,
        strokeColor: "#ff5454",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        map: map,
      });

      return () => {
        circle.setMap(null);
      };
    }
  }, [map, latitude, longitude, isApproximate]);

  return (
    <Map
      defaultZoom={12}
      defaultCenter={{ lat: latitude, lng: longitude }}
      mapId={mapsId}
      className='map'
      streetViewControl={false}
    >
      {/* Draw marker if the location is not approximate */}
      {!isApproximate && (
        <AdvancedMarker position={{ lat: latitude, lng: longitude }}>
          <img src="/request_marker.png" width={42} height={42} alt='Request Marker' />
        </AdvancedMarker>
      )}
    </Map>
  );
};

export { GoogleMap, OwnRequestMap };